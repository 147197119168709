import axios, { AxiosResponse } from 'axios'


const baseUrl: string = (window as any)._env_.API_URL
const axiosInstance = axios.create({ withCredentials: true })

export const getReports = async (showOption: ReportListType): Promise<AxiosResponse<getReportsApi>> => {
  let ac = ""
  switch (showOption) {
    case 'accepted':
      ac = '/1'
      break
    case 'notAccepted':
      ac = '/0'
      break
  }
  try {
    const reports: AxiosResponse<getReportsApi> = await axiosInstance.get(
      baseUrl + '/api/reports' + ac
    )
    reports.data.WorkReports.items.forEach((val) => {
      val.StartDate = val.StartDate.substring(0, 10)
      val.StartTime = val.StartTime.substring(11, 16)
      val.EndTime = val.EndTime.substring(11, 16)
    })
    return reports
  } catch (error) {
    throw error
  }
}

export const getReportsByDate = async (date: string): Promise<AxiosResponse<getReportsApi>> => {
  try {
    const reports: AxiosResponse<getReportsApi> = await axiosInstance.get(
      baseUrl + '/api/reports?date=' + date
    )
    reports.data.WorkReports.items.forEach((val) => {
      val.StartDate = val.StartDate.substring(0, 10)
      val.StartTime = val.StartTime.substring(11, 16)
      val.EndTime = val.EndTime.substring(11, 16)
    })
    return reports
  } catch (error) {
    throw error
  }
}

export const getWorkerOptions = async (): Promise<IWorkerOptions> => {
  try {
    const workerOptions: AxiosResponse<getWorkerOptionsApi> = await axiosInstance.get(
      baseUrl + '/api/workeroptions'
    )
    return workerOptions.data.WorkerOptions
  } catch (error) {
    throw error
  }
}

export const addReport = async (
  report: WorkReport
): Promise<AxiosResponse<{ message: string }>> => {
  try {
    const newReport = {
      ...report,
      WorkerOption: report.WorkerOption.Id
    }
    const saveReport: AxiosResponse<{ message: string }> = await axiosInstance.post(
      baseUrl + '/api/add-report',
      newReport
    )
    return saveReport
  } catch (error) {
    throw error
  }
}

export const updateReport = async (
  report: WorkReport
): Promise<AxiosResponse<{ message: string }>> => {
  const updateReport = {
    ...report,
    WorkerOption: report.WorkerOption.Id
  }

  try {
    const updatedReport: AxiosResponse<{ message: string }> = await axiosInstance.put(
      `${baseUrl}/api/edit-report`,
      updateReport
    )
    return updatedReport
  } catch (error) {
    throw error
  }
}

export const deleteReport = async (
  report: WorkReport
): Promise<AxiosResponse<{ message: string }>> => {
  try {
    const deletedReport: AxiosResponse<{ message: string }> = await axiosInstance.delete(
      `${baseUrl}/api/delete-report/${report.Id}`
    )
    return deletedReport
  } catch (error) {
    throw error
  }
}

export const loginUser = async (id: string, password: string): Promise<loginUserApi> => {
  const resp: AxiosResponse<loginUserApi> = await axiosInstance.post(
    `${baseUrl}/api/login`,
    { id, password }
  )
  return resp.data
}

export const logoutUser = async (): Promise<any> => {
  const resp: AxiosResponse<any> = await axiosInstance.post(
    `${baseUrl}/api/logout`
  )
  return resp.data
}

export const getUserData = async (): Promise<getUserDataApi> => {
  const resp: AxiosResponse<getUserDataApi> = await axiosInstance.get(
    `${baseUrl}/api/user-data`
  ).catch((error) => {
    throw error
  })
  return resp.data
}

export const passwordChange = async (oldPassword: string, newPassword: string): Promise<AxiosResponse<{ message: string }>> => {
  try {
    const paswordChanged: AxiosResponse<{ message: string }> = await axiosInstance.put(
      `${baseUrl}/api/password`,
      { oldPassword, newPassword }
    )
    return paswordChanged
  } catch (error) {
    throw error
  }
}