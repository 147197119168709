const lang = {
    LANGUAGE_NAME: "English",
    LANGUAGE_CODE: "EN",

    LOGIN_WRONG_LOGIN: "Wrong login",
    LOGIN_SERVER_ERROR: "Server error",
    LOGIN_LOGIN_HEADER: "Work Reports",
    LOGIN_LOGIN_SUBHEADER: "Login",
    LOGIN_FORM_EMAIL: "Email",
    LOGIN_FORM_PASSWORD: "Password",
    LOGIN_FORM_SUBMIT: "Login",
    LOGIN_FORM_WARNING: "Warning",

    PASSWORD_CHANGE_MISMATCH: "Passwords do not match",
    PASSWORD_CHANGE_WRONG: "Incorrect old password",
    PASSWORD_CHANGE_SERVER_ERROR: "Server error",
    PASSWORD_CHANGE_OLD: "Old password",
    PASSWORD_CHANGE_NEW: "New password",
    PASSWORD_CHANGE_REPEAT: "Repeat new password",
    PASSWORD_CHANGE_SUBMIT: "Change password",
    PASSWORD_CHANGE_WARNING: "Warning",

    REPORT_DETAIL_DURATION: "Duration",
    REPORT_DETAIL_ADD: "Add",
    REPORT_DETAIL_EDIT: "Edit",

    REPORT_DETAIL_MODAL_ADD: "Add report",
    REPORT_DETAIL_MODAL_EDIT: "Edit report",
    REPORT_DETAIL_MODAL_DETAIL: "Report detail",

    REPORT_DETAIL_BUTTON_ADD: "Add report",
    REPORT_DETAIL_BUTTON_ACCEPT: "Confirm selected",

    REPORT_MENU_EDIT: "Edit",
    REPORT_MENU_DETAIL: "Detail",
    REPORT_MENU_ACCEPT: "Accept",
    REPORT_MENU_DELETE: "Delete",

    SIDE_MENU_NOT_CONFIRMED: "Unconfirmed reports",
    SIDE_MENU_CONFIRMED: "Confirmed reports",
    SIDE_MENU_LOGOUT: "Logout",
    SIDE_MENU_PASSWORD_CHANGE: "Change password",

    NO_PROJECT_FILTER: "All projects",
    HOURS_SUM: "Total hours",

    MONTHS: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],

    PROJECT: "Project",
    PROFESSION: "Profession",
    DATE: "Date",
    START_TIME: "Time from",
    END_TIME: "Time to",
    PAUSE: "Pause",
    NOTE: "Note",
  };
  
  export default lang;