import * as React from 'react';
import Button from '@mui/joy/Button';
import Stack from '@mui/joy/Stack';
import ReportDetailModal from './ReportDetailModal';
import { getReports, getWorkerOptions, updateReport } from '../../API'
import { Navigate } from "react-router-dom";
import { LanguageContext } from '../../Context';
import { Box, Input, Select, Option, Typography } from '@mui/joy';
import ReportListDesktop from './ReportListDesktop';
import ResponsiveComponent from '../ReactiveComponent';
import ReportListMobile from './ReportListMobile';
import { convertMinutesTimeString, convertTimeStringToMinutes } from '../utils';

type Props = {
  showOption: ReportListType
}

type DateFilter = {
  month: number
  year: number
}

type Filter = {
  date: DateFilter | null
  projectId: number | null
}

function parseDate(date: string): DateFilter | null {
  if (date === '') return null;
  const [year, month] = date.split('-')
  return { month: parseInt(month), year: parseInt(year) }
}

const ReportList: React.FC<Props> = ({ showOption }) => {
  const l = React.useContext(LanguageContext);	
  const [reports, setReports] = React.useState<IWorkReports>()
  const [selected, setSelected] = React.useState<readonly WorkReport[]>([]);
  const [fetchError, setFetchError] = React.useState<boolean>(false);
  const [filter, setFilter] = React.useState<Filter>({date: null, projectId: null});
  const [workerOptions, setWorkerOptions] = React.useState<IWorkerOptions>({ items: [] });

  const fetchReports = (): void => {
    setSelected([]);
    getReports('all')
      .then(({ data: { WorkReports } }: IWorkReports | any) => {
        setReports(WorkReports)
      })
      .catch((err) => {
        console.log(err)
        if (err.response.status === 401) {
          setFetchError(true)
        }
      })
  }

  const fetchWorkerOptions = (): Promise<any> => {
    return getWorkerOptions()
      .then((data) => {
        setWorkerOptions(data)
      })
      .catch((err: Error) => console.log(err))
  }

  

  React.useEffect(() => {
    fetchReports()
  }, [])

  React.useEffect(() => {
    fetchWorkerOptions()
  }, [])

  // all projects of current worker (including old inactive ones)
  const projects = React.useMemo(() => {
    return workerOptions.items.reduce((acc: Project[], row) => {
      if (acc.findIndex((el) => el.Id === row.Project.Id) === -1) {
        acc.push(row.Project)
      }
      return acc;
    }, [])
  }, [workerOptions]);

  // projects filtered by date
  const filteredProjects = React.useMemo(() => {
    if (filter.date) {
      var filterDateStart = new Date(filter.date.year, filter.date.month-1, 1);
      var filterDateEnd = new Date(filter.date.year, filter.date.month, 0);
      return workerOptions.items.filter((row) => {return (new Date(row.SinceDate) <= filterDateEnd && 
                                                          new Date(row.TillDate) >= filterDateStart)})
                                .reduce((acc: Project[], row) => {
                                      if (acc.findIndex((el) => el.Id === row.Project.Id) === -1) {
                                        acc.push(row.Project)
                                      }
                                      return acc;
                                  }, [])
    }
    return projects;
    }, [workerOptions, filter, projects]);
      
      
  const filteredReports = React.useMemo(() => {
    if (reports) {
      return {items:reports.items.filter((row) => {
        if (showOption === 'accepted' && !row.Accepted) {
          return false;
        }
        if (showOption === 'notAccepted' && row.Accepted) {
          return false;
        }
        if (filter.date !== null && (new Date(row.StartDate).getMonth() + 1) !== filter.date.month) {
          return false;
        }
        if (filter.date !== null && new Date(row.StartDate).getFullYear() !== filter.date.year) {
          return false;
        }
        if (filter.projectId !== null && row.WorkerOption.Project.Id !== filter.projectId) {
          return false;
        }
        return true;
      }).sort((a, b) => {
        var datecmp = new Date(b.StartDate).getTime() - new Date(a.StartDate).getTime();
        if (datecmp !== 0) return datecmp;
        var timecmp = new Date(b.StartTime).getTime() - new Date(a.StartTime).getTime();
        if (timecmp !== 0) return timecmp;
        return a.WorkerOption.Project.Id - b.WorkerOption.Project.Id;
      })}
    }
    return {items:[]};
  }, [reports, filter, showOption]);
  
  // minutes sum of filtered reports
  const minutesSum = React.useMemo(() => {
    return filteredReports.items.map((row) => {
      return convertTimeStringToMinutes(row.EndTime) - convertTimeStringToMinutes(row.StartTime) - row.PauseMin;
    }).reduce((acc, row) => acc + row, 0);
  }, [filteredReports]);

  const [dialogProps, manageModal] = React.useState<{ open: boolean, type?: ReportDetailType }>({
    open: false,
    type: 'detail',
  });

  const handleAcceptButtonClick = () => {
    let proms: Promise<any>[] = []
    selected.forEach(element => {
      element.Accepted = true
      proms.push(updateReport(element))
    })
    Promise.all(proms).then(fetchReports)
  }

  const BUTTON_ADD = <> <Button
                          sx={showOption === 'accepted' ? { display: 'none' } : {}}
                          onClick={() => manageModal({ open: true, type: 'add' })}>
                          {l.REPORT_DETAIL_BUTTON_ADD}
                        </Button> </>

  const BUTTON_ACCEPT = <><Button
                            disabled={selected.length <= 0}
                            sx={showOption === 'accepted' ? { display: 'none' } : {}}
                            onClick={handleAcceptButtonClick}>
                            {l.REPORT_DETAIL_BUTTON_ACCEPT}
                          </Button> </>

  const INPUT_MONTH = <><Input
                          type='month'
                          onChange={(e) => setFilter({...filter, date:parseDate(e.target.value)})} /></>
  
  const SELECT_PROJECT = <><Select
                              placeholder={null}
                              name="project"
                              sx={{ minWidth: 200 }}
                              onChange={(e, val) => setFilter({...filter, projectId: val as number | null})}
                            >
                            {
                            [(<Option key={null} value={null}>{l.NO_PROJECT_FILTER}</Option>)].concat(
                            filteredProjects.map((row) => {
                                return (
                                  <Option key={row.Id.toString()} value={row.Id}>
                                    {row.Name}
                                  </Option>
                                )
                              }))
                            }
                            </Select></>

  const TYPOGRAPHY_HOURS_SUM = <><Typography>{l.HOURS_SUM} : {convertMinutesTimeString(minutesSum)}</Typography></>

  return (
    fetchError ? <Navigate to="/login" /> :
      <Box sx={{display:'flex', flexDirection:'column', height:'100%'}}>

        <ResponsiveComponent>
        {
          ({ size }) => size === 'xs' ? 
          <>
          <Stack
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            spacing={2}
            py={'4px'}
            sx={{ flexGrow: 0, flexShrink: 0 }}
          >
            {INPUT_MONTH}
            {SELECT_PROJECT}
          </Stack>
          <Stack
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            spacing={2}
            py={'4px'}
            sx={{ flexGrow: 0, flexShrink: 0 }}
          >
            {BUTTON_ADD}
            {BUTTON_ACCEPT}
          </Stack>
          <Stack
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            spacing={2}
            py={'4px'}
            sx={{ flexGrow: 0, flexShrink: 0 }}
          >
            {TYPOGRAPHY_HOURS_SUM}
          </Stack>
          </>
          :
          <Stack
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            spacing={2}
            sx={{ flexGrow: 0, flexShrink: 0 }}
          >
            {BUTTON_ADD}
            {BUTTON_ACCEPT}
            {INPUT_MONTH}
            {SELECT_PROJECT}
            {TYPOGRAPHY_HOURS_SUM}
          </Stack>
        }
        </ResponsiveComponent>

        <Box sx={{overflowY:'scroll', overflowX:'auto', flexGrow:1, flexShrink:1}}>
        <ResponsiveComponent>
          {({ size }) => size === 'xs' ? 
          <ReportListMobile showOption={showOption} 
            selected={selected} 
            setSelected={setSelected} 
            reports={filteredReports}
            manageModal={manageModal}
            fetchReports={fetchReports} /> : 
          <ReportListDesktop showOption={showOption} 
            selected={selected} 
            setSelected={setSelected} 
            reports={filteredReports}
            manageModal={manageModal}
            fetchReports={fetchReports} />}  
        </ResponsiveComponent>
        
        </Box>
        <ReportDetailModal
          openDialog={dialogProps.open}
          doClose={() => manageModal({ open: false })}
          reportType={dialogProps.type}
          reportListChanged={() => { fetchReports(); manageModal({ open: false }); }}
          reportData={{
            Id: 0,
            StartDate: new Date().getFullYear().toString() + '-' + (new Date().getMonth() + 1).toString().padStart(2,"0") + '-' + new Date().getDate().toString().padStart(2,"0"),
            StartTime: "07:00",
            EndTime: "15:30",
            PauseMin: 30,
            Accepted: false,
            Note: "",
            WorkerOption: {
              Id: 0,
              WorkerId: 0,
              SinceDate: new Date(),
              TillDate: new Date(),
              Active: true,
              Project: {
                Id: filter.projectId ? filter.projectId : 0,
                Name: ""
              },
              Profession: {
                Id: 0,
                Name: ""
              }
            }
          }}
        />
      </Box>
  );
}

export default ReportList
