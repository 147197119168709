import React, { useState, useEffect, ChangeEvent } from 'react'
import Select from '@mui/joy/Select';
import Option from '@mui/joy/Option';
import Stack from '@mui/joy/Stack';
import Input from '@mui/joy/Input';
import Textarea from '@mui/joy/Textarea';
import 'moment/locale/cs';
import moment from "moment"
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import Button from '@mui/joy/Button';
import { Box } from '@mui/material';
import { addReport, updateReport, getWorkerOptions, getReportsByDate } from '../../API'
import { LanguageContext } from '../../Context';
import { convertMinutesTimeString, convertTimeStringToMinutes } from '../utils';


type Props = {
  reportType: ReportDetailType
  reportData?: WorkReport
  reportListChanged?: () => void
}

const ReportDetail: React.FC<Props> = ({ reportType, reportData, reportListChanged }) => {
  const l = React.useContext(LanguageContext);

  const initReportData = (): WorkReport => {
    return reportData ? reportData : {
      Id: 0,
      StartDate: new Date().getFullYear().toString() + '-' + (new Date().getMonth() + 1).toString().padStart(2,"0") + '-' + new Date().getDate().toString().padStart(2,"0"),
      StartTime: "07:00",
      EndTime: "15:30",
      PauseMin: 30,
      Accepted: false,
      Note: "",
      WorkerOption: {
        Id: 0,
        WorkerId: 0,
        SinceDate: new Date(),
        TillDate: new Date(),
        Active: true,
        Project: {
          Id: 0,
          Name: ""
        },
        Profession: {
          Id: 0,
          Name: ""
        }
      }
    }
  }

  const [selectedProjectId, setSelectedProjectId] = useState<number | null>();
  const [selectedProfessionId, setSelectedProfessionId] = useState<number | null>();
  const [report, setReportData] = useState<WorkReport>(initReportData);
  const [projects, setProjects] = useState<Project[]>([]);
  const [duration, setDuration] = useState('00:00');
  const [workerOptions, setWorkerOptions] = useState<IWorkerOptions>({ items: [] });

  // https://mui.com/joy-ui/react-input/#newsletter-subscription
  const [stat, setStatus] = React.useState<{
    status: 'initial' | 'loading' | 'failure' | 'sent';
  }>({
    status: 'initial',
  });

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setStatus((current) => ({ ...current, status: 'loading' }));
    const formData = new FormData(event.currentTarget);
    const formJson = Object.fromEntries((formData as any).entries());
    // todo: use IReport interface
    console.log(formJson);
    console.log(reportData);
    try {
      // Replace timeout with real backend operation
      setTimeout(() => {
        setStatus({ status: 'sent' });
      }, 1500);
    } catch (error) {
      setStatus((current) => ({ ...current, status: 'failure' }));
    }
  };

  React.useEffect(() => {
    fetchWorkerOptions().then(() => {
      if (reportData) {
        setSelectedProjectId(reportData.WorkerOption.Project.Id)
        setSelectedProfessionId(reportData.WorkerOption.Profession.Id)
      }
    })
  }, [])

  const fetchWorkerOptions = (): Promise<any> => {
    return getWorkerOptions()
      .then((data) => {
        setWorkerOptions(data)
        calculateDistinctProjects(data, report)
        if (reportType === 'add') {
          calculateNextStartTime(report)
        }
      })
      .catch((err: Error) => console.log(err))
  }

  const calculateDistinctProjects = (wo: IWorkerOptions, rep: WorkReport): void => {
    const distinctIds: number[] = [];
    const distinctProjects: Project[] = [];
    wo.items.map((item) => {
      const sinceDate = new Date(item.SinceDate)
      const tillDate = new Date(item.TillDate)
      const selectedDate = new Date(rep.StartDate)
      if (distinctIds.indexOf(item.Project.Id) === -1 && sinceDate <= selectedDate && selectedDate <= tillDate) {
        distinctIds.push(item.Project.Id)
        distinctProjects.push(item.Project)
      }
    });
    setProjects(distinctProjects);
  }

  const calculateNextStartTime = (rep: WorkReport): void => {
    console.log('calculating next start time: ' + rep.StartDate);
    getReportsByDate(rep.StartDate)
      .then(( { data: {WorkReports} } ) => {
        const newStartTime = WorkReports.items.reduce((accumulator, current) => (accumulator > current.EndTime ? accumulator : current.EndTime), "07:00");
        setReportData({
          ...rep,
          StartTime: newStartTime
        });
      });
  }

  useEffect(() => {
    let te = {
      timeStart: moment(report.StartTime, 'HH:mm'),
      timeEnd: moment(report.EndTime, 'HH:mm'),
      pause: report.PauseMin
    }
    if (te.timeEnd <= te.timeStart) te.timeEnd = te.timeEnd.clone().add(1, 'days');
    const totalTimeInMin = te.timeEnd.diff(te.timeStart, 'minutes') - te.pause;

    setDuration(convertMinutesTimeString(totalTimeInMin));
  }, [report.StartTime, report.EndTime, report.PauseMin]);

  const handleInputDataChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, componentName: string) => {
    const newData: WorkReport = {
      ...report,
      [componentName]: componentName === 'PauseMin' ? convertTimeStringToMinutes(e.target.value) : e.target.value
    }
    setReportData(newData);
    
    if (componentName === "StartDate") {
      calculateDistinctProjects(workerOptions, newData);
      calculateNextStartTime(newData);
    }
  }

  const handleProjectDataChange = (newValue: number | null) => {
    setSelectedProjectId(newValue)
  }

  const handleProfessionDataChange = (event: React.MouseEvent<Element, MouseEvent> | React.KeyboardEvent<Element> | React.FocusEvent<Element, Element> | null, newValue: number | null) => {
    setSelectedProfessionId(newValue)
    const wo = workerOptions.items.find(function (el: WorkerOption) {
      return el.Profession.Id == newValue && el.Project.Id == selectedProjectId
    })
    setReportData(rd => ({
      ...rd,
      WorkerOption: wo ? wo : initReportData().WorkerOption
    }))
  }

  const handleSaveReport = (e: React.FormEvent): void => {
    e.preventDefault()
    if (reportType === 'add') {
      addReport(report)
        .then(({ status }) => {
          console.log(status)
          if (status !== 201) {
            throw new Error('Error! Report not added')
          }
          if (reportListChanged) {

            reportListChanged()
          }
          setReportData(initReportData);
        })
        .catch((err) => console.log(err))
    } else if (reportType === 'edit') {
      updateReport(report)
        .then(({ status }) => {
          if (status !== 200) {
            throw new Error('Error! Report not updated')
          }
          if (reportListChanged) reportListChanged()
          setReportData(initReportData);
        })
        .catch((err) => console.log(err))
    }
  }

  return (
    <form onSubmit={(e) => handleSaveReport(e)}>
      <Stack spacing={1} maxWidth={'500px'} sx={{overflowX:'auto'}} maxHeight='100%'>
        <FormControl>
          <FormLabel>{l.DATE}</FormLabel>
          <Input
            disabled={reportType === 'detail'}
            type='date'
            value={report.StartDate}
            onChange={(e) => { handleInputDataChange(e, 'StartDate') }} />
        </FormControl>
        <FormControl>
          <FormLabel>{l.PROJECT}</FormLabel>
          <Select
            placeholder={l.PROJECT}
            name="project"
            required
            sx={{ minWidth: 200 }}
            disabled={reportType === 'detail'}
            value={selectedProjectId || 0}
            onChange={(e, val) => { handleProjectDataChange(val) }}
          >
            {
              projects.map((row) => {
                return (
                  <Option key={row.Id.toString()} value={row.Id}>{row.Name}</Option>
                )
              })
            }
          </Select>
        </FormControl>
        <FormControl>
          <FormLabel>{l.PROFESSION}</FormLabel>
          <Select
            placeholder={l.PROFESSION}
            name="profession"
            required
            sx={{ minWidth: 200 }}
            disabled={reportType === 'detail'}
            value={selectedProfessionId || 0}
            onChange={(e, val) => { handleProfessionDataChange(e, val) }}
          >
            {
              workerOptions.items.map((row) => {
              if (row.Project.Id === selectedProjectId && new Date(row.SinceDate) <= new Date(report.StartDate) && new Date(report.StartDate) <= new Date(row.TillDate))
                  return (
                    <Option key={selectedProjectId + '_' + row.Profession.Id.toString()} value={row.Profession.Id}>{row.Profession.Name}</Option>
                  )
              })
            }
          </Select>
        </FormControl>
        <FormControl>
          <FormLabel>{l.START_TIME}</FormLabel>
          <Input
            disabled={reportType === 'detail'}
            type='time'
            value={report.StartTime}
            onChange={(e) => { handleInputDataChange(e, 'StartTime') }} />
        </FormControl>
        <FormControl>
          <FormLabel>{l.END_TIME}</FormLabel>
          <Input
            disabled={reportType === 'detail'}
            type='time'
            value={report.EndTime}
            onChange={(e) => { handleInputDataChange(e, 'EndTime') }} />
        </FormControl>
        <FormControl>
          <FormLabel>{l.PAUSE}</FormLabel>
          <Input
            disabled={reportType === 'detail'}
            type='time'
            value={convertMinutesTimeString(report.PauseMin)}
            onChange={(e) => { handleInputDataChange(e, 'PauseMin') }} />
        </FormControl>
        <FormControl>
          <FormLabel>{l.REPORT_DETAIL_DURATION}</FormLabel>
          <Input type='text' value={duration} disabled readOnly />
        </FormControl>
        <FormControl>
          <FormLabel>{l.NOTE}</FormLabel>
          <Textarea
            disabled={reportType === 'detail'}
            name="note"
            placeholder={l.NOTE}
            variant="outlined"
            value={report.Note}
            maxRows={4}
            onChange={(e) => { handleInputDataChange(e, 'Note') }} />
        </FormControl>
        <Box hidden={reportType === 'detail'} >
          <Button
            type="submit">
            {reportType === 'add' ? l.REPORT_DETAIL_ADD : (reportType === 'edit' ? l.REPORT_DETAIL_EDIT : '')}
          </Button>
        </Box>
      </Stack>
    </form>
  )
}

export default ReportDetail
