import React, { useContext, useEffect, useState } from 'react'
import { CssVarsProvider } from '@mui/joy/styles';
import Sheet from '@mui/joy/Sheet';
import Typography from '@mui/joy/Typography';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import Input from '@mui/joy/Input';
import Button from '@mui/joy/Button';
import { useNavigate } from "react-router-dom";
import sha256 from 'crypto-js/sha256';
import Alert from '@mui/joy/Alert';
import WarningIcon from '@mui/icons-material/Warning';
import { LanguageContext } from '../Context';
import User from '../user';
import { AxiosError } from 'axios';
import { wait } from '@testing-library/user-event/dist/utils';

type Props = {
  setUser: (user: User | null) => void;
}

const Login: React.FC<Props> = ({ setUser }) => {

  const l = useContext(LanguageContext);

  const [warning, setWarning] = useState("");
  const navigate = useNavigate();

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    const hashedPassword = sha256(formData.get("password") as string).toString();
    const user = new User(formData.get("email") as string);
    return user.login(hashedPassword)
        .then((resp) => user.getUserData())
        .then(() => {
          setUser(user);
          console.log("User logged in " + user.email + " " + user.partner + " " + user.worker);
        })
        .then(() => navigate("/reports"))
        .catch((error) => {
          if (error instanceof AxiosError && error.response?.status === 401) {
            setWarning(l.LOGIN_WRONG_LOGIN);
          } else {
            setWarning(l.LOGIN_SERVER_ERROR)
          }
          console.log(error)
        });
    }

 // todo: add waiter for api

  return (
    <form onSubmit={handleSubmit}>
      <CssVarsProvider>
        <main>
          <Sheet
            sx={{
              width: 300,
              mx: 'auto', // margin left & right
              my: 4, // margin top & bottom
              py: 3, // padding top & bottom
              px: 2, // padding left & right
              display: 'flex',
              flexDirection: 'column',
              gap: 2,
              borderRadius: 'sm',
              boxShadow: 'md',
            }}
            variant="outlined"
          >
            <div>
              <Typography level="h4" component="h1">
                <b>{l.LOGIN_LOGIN_HEADER}</b>
              </Typography>
              <Typography level="body-sm">{l.LOGIN_LOGIN_SUBHEADER}</Typography>
            </div>
            <FormControl>
              <FormLabel>{l.LOGIN_FORM_EMAIL}</FormLabel>
              <Input
                // html input attribute
                name="email"
                type="email"
                required
                autoComplete='on'
              />
            </FormControl>
            <FormControl>
              <FormLabel>{l.LOGIN_FORM_PASSWORD}</FormLabel>
              <Input
                // html input attribute
                name="password"
                type="password"
                placeholder={l.LOGIN_FORM_PASSWORD}
                required
                autoComplete='on'
              />
            </FormControl>
            <Button sx={{ mt: 1 /* margin top */ }} type="submit">{l.LOGIN_FORM_SUBMIT}</Button>
            {warning !== "" ? (
              <Alert
                sx={{ alignItems: 'flex-start' }}
                startDecorator={<WarningIcon />}
                variant="soft"
                color='warning'
              >
                <div>
                  <div>{l.LOGIN_FORM_WARNING}</div>
                  <Typography level="body-sm" color='warning'>
                    {warning}
                  </Typography>
                </div>
              </Alert>
            ) : (<></>)
            }
          </Sheet>
        </main>
      </CssVarsProvider>
    </form>
  );
}
export default Login
