import { useState, useEffect } from 'react';

export function openSideMenu() {
  if (typeof window !== 'undefined') {
    document.body.style.overflow = 'hidden';
    document.documentElement.style.setProperty('--SideMenu-slideIn', '1');
  }
}
  
export function closeSideMenu() {
  if (typeof window !== 'undefined') {
    document.documentElement.style.removeProperty('--SideMenu-slideIn');
    document.body.style.removeProperty('overflow');
  }
}

export function toggleSideMenu() {
  if (typeof window !== 'undefined' && typeof document !== 'undefined') {
    const slideIn = window
      .getComputedStyle(document.documentElement)
      .getPropertyValue('--SideMenu-slideIn');
    if (slideIn) {
      closeSideMenu();
    } else {
      openSideMenu();
    }
  }
}

export function convertTimeStringToMinutes(timeString: string) {
  return Number(timeString.split(":")[0]) * 60 + Number(timeString.split(":")[1])
}

export function convertMinutesTimeString(minutes: number) {
  return String(Math.floor(minutes / 60)).padStart(2, '0') + ':' + String(minutes % 60).padStart(2, '0')
}


export default function useLongPress(callback = () => {}, ms = 300) {
  const [startLongPress, setStartLongPress] = useState(false);

  useEffect(() => {
    let timerId: NodeJS.Timeout;
    if (startLongPress) {
      timerId = setTimeout(callback, ms);
    } else {
      clearTimeout(timerId!);
    }

    return () => {
      clearTimeout(timerId);
    };
  }, [callback, ms, startLongPress]);

  return {
    onMouseDown: () => setStartLongPress(true),
    onMouseUp: () => setStartLongPress(false),
    onMouseLeave: () => setStartLongPress(false),
    onTouchStart: () => setStartLongPress(true),
    onTouchEnd: () => setStartLongPress(false),
  };
}