import React, { useContext, useState } from 'react'
import Sheet from '@mui/joy/Sheet';
import Typography from '@mui/joy/Typography';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import Input from '@mui/joy/Input';
import Button from '@mui/joy/Button';
import sha256 from 'crypto-js/sha256';
import Alert from '@mui/joy/Alert';
import WarningIcon from '@mui/icons-material/Warning';
import { passwordChange } from '../API'
import { LanguageContext } from '../Context';

type Props = {
  passwordChanged: () => void,
}

const Login: React.FC<Props> = ({ passwordChanged }) => {
  const l = useContext(LanguageContext);

  const [warning, setWarning] = useState("");
  const [newPasswordError, setNewPasswordError] = useState(false);
  const [oldPasswordError, setOldPasswordError] = useState(false);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setNewPasswordError(false)
    setOldPasswordError(false)
    const formData = new FormData(event.currentTarget);
    if (formData.get("newPassword") !== formData.get("newPassword2")) {
      setWarning(l.PASSWORD_CHANGE_MISMATCH)
      setNewPasswordError(true)
      return
    }

    const hashedNewPassword = sha256(formData.get("newPassword") as string).toString()
    const hashedOldPassword = sha256(formData.get("oldPassword") as string).toString()

    passwordChange(
      hashedOldPassword,
      hashedNewPassword
    ).then((loginResp) => {
      passwordChanged()
    }).catch((error) => {
      if (error.response.status === 501) {
        setWarning(l.PASSWORD_CHANGE_WRONG)
        setOldPasswordError(true)
      } else {
        setWarning(l.PASSWORD_CHANGE_SERVER_ERROR)
      }
      console.log(error)
    });
  }

  return (
    <form onSubmit={handleSubmit}>

      <Sheet
        sx={{
          width: 300,
          mx: 'auto', // margin left & right
          my: 1, // margin top & bottom
          py: 1, // padding top & bottom
          px: 2, // padding left & right
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
          borderRadius: 'sm',
          boxShadow: 'md',
        }}
        variant="outlined"
      >
        <FormControl>
          <FormLabel>{l.PASSWORD_CHANGE_OLD}</FormLabel>
          <Input
            // html input attribute
            name="oldPassword"
            type="password"
            placeholder={l.PASSWORD_CHANGE_OLD}
            required
            autoComplete='on'
            error={oldPasswordError}
          />
        </FormControl>
        <FormControl>
          <FormLabel>{l.PASSWORD_CHANGE_NEW}</FormLabel>
          <Input
            // html input attribute
            name="newPassword"
            type="password"
            placeholder={l.PASSWORD_CHANGE_NEW}
            required
            autoComplete='on'
          />
        </FormControl>
        <FormControl>
          <FormLabel>{l.PASSWORD_CHANGE_REPEAT}</FormLabel>
          <Input
            // html input attribute
            name="newPassword2"
            type="password"
            placeholder={l.PASSWORD_CHANGE_NEW}
            required
            autoComplete='on'
            error={newPasswordError}
          />
        </FormControl>
        <Button sx={{ mt: 1 /* margin top */ }} type="submit">{l.PASSWORD_CHANGE_SUBMIT}</Button>
        {warning !== "" ? (
          <Alert
            sx={{ alignItems: 'flex-start' }}
            startDecorator={<WarningIcon />}
            variant="soft"
            color='warning'
          >
            <div>
              <div>{l.PASSWORD_CHANGE_WARNING}</div>
              <Typography level="body-sm" color='warning'>
                {warning}
              </Typography>
            </div>
          </Alert>
        ) : (<></>)
        }
      </Sheet>

    </form>
  );
}
export default Login
