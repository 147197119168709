import * as React from 'react';
import Modal from '@mui/joy/Modal';
import ModalDialog from '@mui/joy/ModalDialog';
import DialogTitle from '@mui/joy/DialogTitle';
import ReportDetail from './ReportDetail';
import { LanguageContext } from '../../Context';

type Props = {
  openDialog: boolean,
  doClose: () => void,
  reportType?: ReportDetailType,
  reportData?: WorkReport,
  reportListChanged: () => void
}

const ReportDetailModal: React.FC<Props> = ({ openDialog, doClose, reportType, reportData, reportListChanged }) => {
  const l = React.useContext(LanguageContext);

  return (
    <Modal open={openDialog} onClose={() => { doClose() }}>
      <ModalDialog sx={{overflowX:'auto'}}>
        <DialogTitle>{
          reportType ?
            {
              'add': l.REPORT_DETAIL_MODAL_ADD,
              'edit': l.REPORT_DETAIL_MODAL_EDIT,
              'detail': l.REPORT_DETAIL_MODAL_DETAIL,
            }[reportType]
            : ''
        }</DialogTitle>

        <ReportDetail reportType={reportType ?? 'detail'} reportData={reportData} reportListChanged={reportListChanged} />

      </ModalDialog>
    </Modal>

  );
}
export default ReportDetailModal