// create react mui joy language selector component
import React from 'react';
import { Dropdown, Menu, MenuButton, MenuItem } from '@mui/joy';
import { LanguageContext } from '../Context';
import LANGS from '../i18n/LANGS';

type LanguageSelectorProps = {
  languageChange: (languageCode: string) => void;
};

export default function LanguageSelector(props: LanguageSelectorProps) {
  const l = React.useContext(LanguageContext);
  return (
    <Dropdown>
        <MenuButton>{l.LANGUAGE_NAME}</MenuButton>
        <Menu>
          {Object.keys(LANGS).map((lang) => (
            <MenuItem key={lang} onClick={() => props.languageChange(lang)}>
              {LANGS[lang].LANGUAGE_NAME}
            </MenuItem>
          ))}
        </Menu>
    </Dropdown>
  );
}