import React, { useEffect } from 'react'
import { Helmet, HelmetProvider } from 'react-helmet-async';
import './App.css';
import { CssVarsProvider } from '@mui/joy/styles';
import CssBaseline from '@mui/joy/CssBaseline';
import Box from '@mui/joy/Box';
import Login from './components/Login';
import SideMenu from './components/SideMenu';
import ReportList from './components/reports/ReportList';
import Header from './components/Header';
import { Navigate, createBrowserRouter, Outlet, RouterProvider } from "react-router-dom";
import User from "./user";
import LANGS from './i18n/LANGS';
import { LanguageContext, UserContext } from './Context';
import { logoutUser } from './API'

function getDefaultLanguageCode() {
  var lang = localStorage.getItem('language');
  if (lang !== null && LANGS[lang.toUpperCase()] !== undefined) {
    return lang.toUpperCase();
  }
  return 'CS';
}

function App() {

  const [language, setLanguage] = React.useState(LANGS.CS);
  const [user, setUser] = React.useState<User | null>(null);
  const [userLoaded, setUserLoaded] = React.useState(user !== null);
  const [menuIndex, setMenuIndex] = React.useState(0);

  const changeLanguage = (languageCode: string) => {
    var lang = LANGS[languageCode.toUpperCase()];
    if (lang === undefined) {
      return;
    }
    setLanguage(lang);
    document.documentElement.lang = languageCode.toLowerCase();
    localStorage.setItem('language', languageCode);
  };

  const parseLoggedInUser = () => {
    var loggedInUser = new User();
    return loggedInUser.getUserData()
              .then(() => {
                setUser(loggedInUser);
              })
              .catch(() => {
                setUser(null);
              })
              .finally(() => setUserLoaded(true));
  };

  useEffect(() => {
    changeLanguage(getDefaultLanguageCode());
  }, []);

  useEffect(() => {
    if (user === null && !userLoaded) {
      parseLoggedInUser();
    }
  }, [user, userLoaded]);

  const Layout = () => {
    return (
      <Box sx={{height:'100dvh', display:'flex', flexDirection:'column', overflow:'hidden'}}>
        <Header languageChange={changeLanguage} />
        <Outlet />
      </Box>
    )
  };

  const handleSideMenuIndexChanged = (index: number) => {
    setMenuIndex(index)
  };

  const handleLogout = () => {
    logoutUser();
    setUser(null);
  };

  const ReportListLayout = (showOption: ReportListType) => (
    <>
      <Box sx={{ display: 'flex', flexGrow:1, overflow:'hidden'}}>
        <SideMenu menuIndexChanged={handleSideMenuIndexChanged} menuInitIndex={menuIndex} logout={handleLogout}/>
        <Box
          component="main"
          className="MainContent"
          sx={{
            px: { xs: 2, md: 2 },
            pt: {
              xs: 'calc(12px + var(--Header-height))',
              sm: 'calc(12px + var(--Header-height))',
              md: 3,
            },
            pb: { xs: 2, sm: 2, md: 3 },
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            minWidth: 0,
            overflow: 'hidden',
            height: 'auto',
            gap: 1,
          }}
        >
          <Box
            sx={{
              display: 'flex',
              mb: 1,
              gap: 1,
              flexDirection: { xs: 'column', sm: 'row' },
              alignItems: { xs: 'start', sm: 'center' },
              flexWrap: 'wrap',
              justifyContent: 'space-between',
            }}
          >
          </Box>
          <ReportList showOption={showOption}/>
        </Box>
      </Box>

    </>
  );

  const routers = createBrowserRouter([
    {
      element: Layout(),
      children: [
        {
          path: "/reports",
          element: userLoaded && user === null ? <Navigate to="/login"/> : 
                                                ReportListLayout(menuIndex === 0 ? 'notAccepted' : 'accepted')
        },
        {
          path: "/login",
          element: <Login setUser={setUser} />
        },
        {
          index: true,
          path: "*",
          element: <Navigate to="/reports" />
        }
      ]
    }
  ]);

  return (
    <HelmetProvider>
    <CssVarsProvider disableTransitionOnChange>
      <LanguageContext.Provider value={language}>
        <UserContext.Provider value={user}>
          <Helmet htmlAttributes={{lang: language.LANGUAGE_CODE.toLocaleLowerCase()}}>
            <meta http-equiv="Content-Language" content={language.LANGUAGE_CODE.toLowerCase()}/>
          </Helmet>
          <CssBaseline />
          <RouterProvider router={routers} />
        </UserContext.Provider>
      </LanguageContext.Provider>
    </CssVarsProvider>
    </HelmetProvider>
  )
}

export default App;
