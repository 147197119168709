import * as React from 'react';
import Table from '@mui/joy/Table';
import Checkbox from '@mui/joy/Checkbox';
import ReportDropdownMenu from './ReportDropdownMenu';
import { LanguageContext } from '../../Context';

interface Props {
    showOption: ReportListType;
    selected: readonly WorkReport[];
    setSelected: React.Dispatch<React.SetStateAction<readonly WorkReport[]>>;
    reports: IWorkReports | undefined;
    manageModal: (props: { open: boolean; type?: 'add' | 'edit' }) => void;
    fetchReports: () => void;
}

interface EnhancedTableProps {
    numSelected: number;
    onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
    rowCount: number;
    showOption: ReportListType;
}
  
function EnhancedTableHead(props: EnhancedTableProps) {
    const l = React.useContext(LanguageContext);	
    const { onSelectAllClick, numSelected, rowCount, showOption } = props;

    return (
        <thead style={{position:'sticky', top:'0', backgroundColor:'var(--joy-palette-background-body)', zIndex:14}}>
        <tr>
            <th style={{ width: '40px' }} hidden={props.showOption === 'accepted'}>
            <Checkbox
                indeterminate={numSelected > 0 && numSelected < rowCount}
                checked={rowCount > 0 && numSelected === rowCount}
                onChange={onSelectAllClick}
                slotProps={{
                input: {
                    'aria-label': 'select all reports',
                },
                }}
                sx={{ verticalAlign: 'sub' }}

            />
            </th>
            <th>{l.PROJECT}</th>
            <th>{l.PROFESSION}</th>
            <th>{l.DATE}</th>
            <th>{l.START_TIME}</th>
            <th>{l.END_TIME}</th>
            <th>{l.PAUSE}</th>
            <th>{l.NOTE}</th>
            <th aria-label="last"
            style={{ width: 'var(--Table-lastColumnWidth)' }}
            hidden={props.showOption === 'accepted'}
            />
        </tr>
        </thead>
    );
}

const ReportListDesktop: React.FC<Props> = ({ showOption, selected, setSelected, reports, fetchReports}) => {
    const l = React.useContext(LanguageContext);

    const isSelected = (report: WorkReport) => selected.indexOf(report) !== -1;

    const handleSelectOne = (event: React.ChangeEvent<HTMLInputElement>, report: WorkReport) => {
        if (event.target.checked) {
            const newSelected = selected.map((n) => n);
            newSelected.push(report);
            setSelected(newSelected);
            return;
        }
        const newSelected = selected.filter(function (el) { return el !== report; });
        setSelected(newSelected);
    };

    const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.checked) {
        const newSelected = reports?.items.map((n) => n);
        if (newSelected) setSelected(newSelected);
        return;
      }
      setSelected([]);
    };

    return (
    <Table hoverRow>
      <EnhancedTableHead
        numSelected={selected.length}
        onSelectAllClick={handleSelectAllClick}
        rowCount={reports ? reports.items.length : 0}
        showOption={showOption}
      />
      <tbody>
        {reports?.items.map((row, index) => {
          const isItemSelected = isSelected(row);
          const labelId = `enhanced-table-checkbox-${index}`;
          return (
            <tr key={row.Id}>
              <th scope="row" hidden={showOption === 'accepted'} style={{padding:0, background:'transparent'}}>
                <Checkbox
                  checked={isItemSelected}
                  slotProps={{
                    input: {
                      'aria-labelledby': labelId,
                    },
                  }}
                  sx={{ p:0, m:1 }}
                  onChange={(e) => handleSelectOne(e, row)}
                />
              </th>
              <td data-label={l.PROJECT}>{row.WorkerOption.Project.Name}</td>
              <td data-label={l.PROFESSION}>{row.WorkerOption.Profession.Name}</td>
              <td data-label={l.DATE}>{row.StartDate}</td>
              <td data-label={l.START_TIME}>{row.StartTime}</td>
              <td data-label={l.END_TIME}>{row.EndTime}</td>
              <td data-label={l.PAUSE}>{String(Math.floor(row.PauseMin / 60)).padStart(2, '0') + ':' + String(row.PauseMin % 60).padStart(2, '0')}</td>
              <td data-label={l.NOTE}>{row.Note}</td>
              <td hidden={showOption === 'accepted'}>
                <ReportDropdownMenu reportData={row} reportListChanged={() => fetchReports()} />
              </td>
            </tr>
          )
        })}
      </tbody>
    </Table>
  );
}

export default ReportListDesktop;