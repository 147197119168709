import * as React from 'react';
import Box from '@mui/joy/Box';
import List from '@mui/joy/List';
import ListItem from '@mui/joy/ListItem';
import ListItemDecorator from '@mui/joy/ListItemDecorator';
import ListItemButton from '@mui/joy/ListItemButton';
import Typography from '@mui/joy/Typography';
import Stack from '@mui/joy/Stack';
import Avatar from '@mui/joy/Avatar';
import { Pause, Logout, Check, Password } from '@mui/icons-material';
import ListItemContent from '@mui/joy/ListItemContent';
import ListDivider from '@mui/joy/ListDivider';
import PasswordChange from './PasswordChange'
import Modal from '@mui/joy/Modal';
import ModalDialog from '@mui/joy/ModalDialog';
import DialogTitle from '@mui/joy/DialogTitle';
import { LanguageContext, UserContext } from '../Context';
import Sheet from '@mui/joy/Sheet';
import GlobalStyles from '@mui/joy/GlobalStyles';
import { closeSideMenu } from './utils';


type Props = {
  menuIndexChanged(index: number): void,
  menuInitIndex: number,
  logout: () => void
}

const SideMenu: React.FC<Props> = ({ menuIndexChanged, menuInitIndex, logout }) => {
  const l = React.useContext(LanguageContext);
  const user = React.useContext(UserContext);
  const [index, setIndex] = React.useState(menuInitIndex);
  const handleIndexChanged = (index: number) => {
    setIndex(index);
    menuIndexChanged(index);
  }
  const [passwordChangeOpen, manageModal] = React.useState<boolean>(false);

  return (
      <Sheet className="SideMenu"
        sx={{
          position: { xs: 'fixed', md: 'sticky' },
          zIndex: 1000,
          width: 'var(--Sidebar-width)',
          height: 'calc(100dvh - var(--Header-height))',
          minHeight: 'calc(100dvh - var(--Header-height))',
          transform: { 
            xs: 'translateX(calc(100% * (var(--SideMenu-slideIn, 0) - 1)))',
            md: 'none'
          },
          top: 0,
          p: 0,
          flexShrink: 0,
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
          borderRight: '1px solid',
          borderColor: 'divider',
          bottom: 0,
        }}
      >
        <GlobalStyles
          styles={(theme) => ({
            ':root': {
              '--Sidebar-width': '250px',
              [theme.breakpoints.up('lg')]: {
                '--Sidebar-width': '250px',
              },
            },
          })}
        />
        <Box className="SideMenu-Overlay"
          sx={{
            position: 'fixed',
            zIndex: 998,
            top: 0,
            left: 0,
            width: '100vw',
            height: '100vh',
            opacity: 0.5,
            backgroundColor: 'var(--joy-palette-background-backdrop)',
            transform: {
              xs: 'translateX(calc(100% * (var(--SideMenu-slideIn, 0) - 1) + var(--SideMenu-slideIn, 0) * var(--Sidebar-width, 0px)))',
              md: 'translateX(-100%)',
            }
          }}
          onClick={() => closeSideMenu()}
          >
        </Box>
      <Box
        sx={{
          // flexGrow: 1,
          display: 'flex',
          justifyContent: 'left',
          gap: 1,
          // flexWrap: 'wrap',
        }}>


        <List
          size="md"
          variant="outlined"
          sx={{
            borderRadius: 'sm',
            "--ListDivider-gap": "10px"
          }}
        >
          <ListItem>
            <Stack
              direction="column"
              justifyContent="flex-start"
              alignItems="center"
              spacing={0}>
              <Typography level="h4">
                {user && user.partner ? user.partner : ""}
              </Typography>


              <Avatar variant="outlined" />
              <div>{user && user.worker ? user.worker : ""}</div>
              <div>{user && user.email ? user.email : ""}</div>
            </Stack>
          </ListItem>
          <ListDivider inset="gutter" />
          <ListItem>
            <ListItemButton
              selected={index === 0}
              color={index === 0 ? 'primary' : undefined}
              onClick={() => { closeSideMenu(); handleIndexChanged(0); }}
            >
              <ListItemDecorator><Pause /></ListItemDecorator>
              <ListItemContent>{l.SIDE_MENU_NOT_CONFIRMED}</ListItemContent>
            </ListItemButton>
          </ListItem>
          <ListItem>
            <ListItemButton
              selected={index === 1}
              color={index === 1 ? 'primary' : undefined}
              onClick={() => { closeSideMenu(); handleIndexChanged(1); }}
            >
              <ListItemDecorator><Check /></ListItemDecorator>
              <ListItemContent>{l.SIDE_MENU_CONFIRMED}</ListItemContent>
            </ListItemButton>
          </ListItem>
          <ListDivider inset="gutter" />
          <ListItem>
            <ListItemButton
              onClick={() => { closeSideMenu(); manageModal(true); } }
            >
              <ListItemDecorator><Password /></ListItemDecorator>
              <ListItemContent>{l.SIDE_MENU_PASSWORD_CHANGE}</ListItemContent>
            </ListItemButton>
          </ListItem>
          <ListItem>
            <ListItemButton
              onClick={() => {closeSideMenu(); logout(); } }
            >
              <ListItemDecorator><Logout /></ListItemDecorator>
              <ListItemContent>{l.SIDE_MENU_LOGOUT}</ListItemContent>
            </ListItemButton>
          </ListItem>
        </List>


        <Modal open={passwordChangeOpen} onClose={() => { manageModal(false) }}>
          <ModalDialog>
            <DialogTitle>
              <div>
                <Typography level="h4" component="h1">
                  <b>{l.SIDE_MENU_PASSWORD_CHANGE}</b>
                </Typography>
                <Typography level="body-sm">{user && user.email ? user.email : ""}</Typography>
              </div>
            </DialogTitle>
            <PasswordChange passwordChanged={() => { manageModal(false) }} />
          </ModalDialog>
        </Modal>
      </Box>
      </Sheet>
  );
}

export default SideMenu