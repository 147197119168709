import * as React from 'react';
import { Button, Sheet, Stack } from '@mui/joy';
import { styled } from '@mui/joy/styles';
import Box from '@mui/joy/Box';
import logo from '../ice.cz-logo-2020-opt.svg';
import LanguageSelector from './LanguageSelector';
import { toggleSideMenu } from './utils';
import { UserContext } from '../Context';

const Item = styled(Sheet)(({ theme }) => ({
  ...theme.typography['h2'],
  textAlign: 'left',
  verticalAlign: 'middle',
  fontWeight: theme.fontWeight.md,
  color: theme.vars.palette.text.secondary,
  border: '1px solid',
  borderColor: theme.palette.divider,
  padding: theme.spacing(1),
  borderRadius: theme.radius.md,
}));

type HeaderProps = {
  languageChange: (languageCode: string) => void;
};

export default function Header(props: HeaderProps) {
  const user = React.useContext(UserContext);
  return (
    <Box sx={{ width: '100%', flexGrow:0, flexShrink:0 }}>
      <Item>
        <Stack spacing={1} justifyContent={'space-between'} direction={'row'}>
            {user && 
            <Button onClick={() => toggleSideMenu()} sx={{
              display: { xs: 'flex', md: 'none' },
            }} >Menu</Button>}
            <img className="App-logo" src={logo} alt='logo'/>
            <LanguageSelector languageChange={props.languageChange}/>
        </Stack>
      </Item>
    </Box>
  );
}