import { Box, Checkbox, Grid, List, ListItem, Sheet, Stack, Typography } from '@mui/joy';
import * as React from 'react';
import { LanguageContext } from '../../Context';
import ReportDropdownMenu from './ReportDropdownMenu';
import useLongPress from '../utils';

interface Props {
    showOption: ReportListType;
    selected: readonly WorkReport[];
    setSelected: React.Dispatch<React.SetStateAction<readonly WorkReport[]>>;
    reports: IWorkReports | undefined;
    manageModal: (props: { open: boolean; type?: 'add' | 'edit' }) => void;
    fetchReports: () => void;
}

const ReportItemRow: React.FC<{name:string, value:string}> = ({name, value}) => {
    return <Box display={'flex'} justifyContent={'space-between'} borderTop={'1px solid'} borderColor={'rgba(var(--joy-palette-neutral-mainChannel, 99 107 116) / 0.2)'}>
        <Typography fontWeight={600} sx={{m:'10px 32px', flexGrow:0}}>{name}</Typography>
        <Typography fontWeight={500} sx={{m:'10px 32px'}}>{value}</Typography>
    </Box>
}

const ReportListMobile: React.FC<Props> = ({ showOption, selected, setSelected, reports, fetchReports}) => {
    const l = React.useContext(LanguageContext);

    const isSelected = (report: WorkReport) => selected.indexOf(report) !== -1;

    const handleSelectOne = (event: React.ChangeEvent<HTMLInputElement>, report: WorkReport) => {
        if (event.target.checked) {
            const newSelected = selected.map((n) => n);
            newSelected.push(report);
            setSelected(newSelected);
            return;
        }
        const newSelected = selected.filter(function (el) { return el !== report; });
        setSelected(newSelected);
    };

    const selectAll = () => {
        const newSelected = reports?.items.map((n) => n);
        if (newSelected) setSelected(newSelected);
    };

    const longPress = useLongPress(selectAll, 1000);

    return (
    <Box sx={{
        width:'100%',
    }}>
    <Sheet sx={{
        height:'10px',
        width:'100%',
        position:'sticky',
        top:'0',
        opacity: 1,
        zIndex: 10,
        backgroundColor: 'var(--joy-palette-background-body)',
    }}></Sheet>
    <List>
        {reports?.items.map((row, index) => {
          const isItemSelected = isSelected(row);
          const labelId = `enhanced-table-checkbox-${index}`;
            return <ListItem sx={{width:'100%' }}>
                <Sheet variant='outlined' 
                        sx={{width:'100%', p:1}}
                        {...longPress}>

                {showOption !== 'accepted' && 
                    <Stack direction='row' justifyContent={'space-between'}>
                        <Checkbox
                        checked={isItemSelected}
                        slotProps={{
                            input: {
                            'aria-labelledby': labelId,
                            },
                        }}
                        sx={{ p:0, m:1 }}
                        onChange={(e) => handleSelectOne(e, row)}
                        />
                    
                    <Box sx={{m:1}}>
                        <ReportDropdownMenu reportData={row} reportListChanged={() => fetchReports()}/>
                    </Box> 
                    </Stack>}

                <ReportItemRow name={l.PROJECT} value={row.WorkerOption.Project.Name} />
                <ReportItemRow name={l.PROFESSION} value={row.WorkerOption.Profession.Name} />
                <ReportItemRow name={l.DATE} value={row.StartDate} />
                <ReportItemRow name={l.START_TIME} value={row.StartTime} />
                <ReportItemRow name={l.END_TIME} value={row.EndTime} />
                <ReportItemRow name={l.PAUSE} value={String(Math.floor(row.PauseMin / 60)).padStart(2, '0') + ':' + String(row.PauseMin % 60).padStart(2, '0')} />
                <ReportItemRow name={l.NOTE} value={row.Note} />
                </Sheet>
            </ListItem>
        })}
    </List>
    </Box>
  );
}

export default ReportListMobile;